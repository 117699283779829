@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text/SF-Pro-Text-Light.ttf');
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text/SF-Pro-Text-Medium.ttf');
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('./assets/fonts/SF-Pro-Text/SF-Pro-Text-Regular.otf');
  font-display: swap;
}

* {
  font-family: 'SF Pro Text', system-ui, sans-serif;
  box-sizing: border-box;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
b, u, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
html, body {
  height: 100%;
  background: #010101;
}

body {
  line-height: 1;
}

:root {
  --text-color: #FFFFFF;
  --reviews-io-widget-star-color: #EB731A;
  --reviews-io-widget-star-color-disabled: rgba(235, 115, 26, 0.5);
  --reviews-io-widget-header-star-color-disabled: rgba(255, 255, 255, 0.5);
  --reviews-io-widget-text-color: #F1F1F1;
  --reviews-io-widget-background-color: #373739;

  --single-choice-button-bg-color: #373739;
  --single-choice-button-color: #FFFFFF;
  --single-choice-button-bg-color-hover: #41352D;
  --single-choice-button-border: 3px solid #373739;
  --single-choice-button-bg-color-selected: #41352D;
  --single-choice-button-border-selected: 3px solid #EB731A;

  --rating-button-bg-color-selected: #EB731A;
}

ol, ul {
  list-style: none;
}

/* @description: Hack for Paywall - for "OR" text */
div[class^="divider"], #primerio-container {
  font-family: 'SF Pro Text', serif;
}

/* Uncomment if React CSS will not work */
/*input[type=range]::-moz-range-thumb,*/
/*input[type=range]::-webkit-slider-thumb,*/
/*input[type=range]::-ms-thumb {*/
/*  cursor: pointer;*/
/*  -webkit-appearance: none;*/
/*  box-sizing: border-box;*/
/*  width: 16px;*/
/*  height: 16px;*/
/*  border-radius: 50%;*/
/*  background: #EB731A;*/
/*  outline: none;*/
/*  border: 1px solid #000000;*/
/*}*/
